import { writable } from 'svelte/store';

export enum NOTIFICATION {
	ALERT,
	ERROR,
	SUCCESS
}

export enum NOTIFICATION_DURATION {
	SHORT = 2000,
	MEDIUM = 3500,
	LONG = 5000
}

export interface Action {
	text: string;
	href?: string;
	onClick?: () => void;
}

export interface AddNotification {
	title: string;
	text: string;
	variant?: NOTIFICATION;
	actions?: Action[];
	hideAfter?: number;
	onClose?: () => void;
}

interface INotification {
	id: number;
	text: string;
	title: string;
	variant: NOTIFICATION;
	actions?: Action[];
	onClose?: () => void;
}

export const createNotificationStore = () => {
	const { set, update, subscribe } = writable<INotification[]>([]);

	const addNotification = (notification: AddNotification) => {
		update((notifications) => {
			const id = notifications.length + 1;
			const newNotification = {
				id,
				...notification
			} as INotification;
			setTimeout(() => {
				removeNotification(id);
			}, notification.hideAfter ?? NOTIFICATION_DURATION.MEDIUM);
			return [...notifications, newNotification];
		});
	};

	const removeNotification = (id: number) => {
		update((notifications) => {
			return notifications.filter((notification) => notification.id !== id);
		});
	};

	return {
		subscribe,
		addNotification,
		removeNotification
	};
};

export const notificationStore = createNotificationStore();
